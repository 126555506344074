html,
body {
  height: 100%;
  min-height: 100%;
}

html {
  overflow-y: scroll;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
